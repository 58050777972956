<template>
  <b-row>
    <b-col cols="12">
      <b-tabs content-class="mt-3" fill>
      <b-tab title="Owned" active>
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">Resources Owned</h5>
        </b-card-body>
        <div class="p-3 d-flex align-items-center">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="9"
            label-size="sm"
            label-class="fw-medium"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-size="sm"
            label-class="fw-medium"
            class="ml-auto"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="danger"
                  :disabled="!filter"
                  @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          
        </div>

        <b-table
          responsive
          class="mb-0"
          head-variant="light"
          :fields="fields"
          :filter="filter"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
        >
        <template #cell(actions)="data">
            <b-button variant="light" class="sm" @click="editRes(data.item.id)">
              <feather type="edit" class="feather-sm"></feather>
            </b-button>
            <b-button variant="danger" class="sm" style="margin:3px" autofocus @click="deleteRes(data.item.id)">
              <feather type="trash" class="feather-sm" ></feather>
            </b-button>
         </template>
          </b-table>
        <div class="p-3 d-flex align-items-center">
          <div class="ml-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </b-card>
      </b-tab>
      <b-tab title="Rented">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">Resources Aquired</h5>
        </b-card-body>
        <div class="p-3 d-flex align-items-center">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="9"
            label-size="sm"
            label-class="fw-medium"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-size="sm"
            label-class="fw-medium"
            class="ml-auto"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="danger"
                  :disabled="!filter"
                  @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          
        </div>

        <b-table
          responsive
          class="mb-0"
          head-variant="light"
          :fields="fields"
          :filter="filter"
          :items="rentedItems"
          :current-page="currentPage"
          :per-page="perPage"
        >
        <template #cell(actions)="data">
            <b-button variant="light" class="sm" @click="editRes(data.item.id)">
              <feather type="edit" class="feather-sm"></feather>
            </b-button>
            <b-button variant="danger" class="sm" style="margin:3px" autofocus @click="deleteRes(data.item.id)">
              <feather type="trash" class="feather-sm" ></feather>
            </b-button>
         </template>
          </b-table>
        <div class="p-3 d-flex align-items-center">
          <div class="ml-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </b-card>
      </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Resource",
  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields:["name","registration","model","make","year","purchase_price","quantity","rental_price","actions"],
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
    filter: null
  }),
  components: {},
  computed: {
    ...mapState({
      authId: (state) => state.auth.authId
    })
  },
  mounted() {
    this.initComp();
  },
  methods: {
    ...mapActions(["getResources","getResourcesByUser","getOrdersByUser","deleteResource"]),
    initComp(){
      this.getResourcesByUser({id:this.authId}).then(res => {
        this.items = res;
        this.totalRows = this.items.length;
      })
      this.getResources().then(res => {
        this.allItems = res;
        //this.totalRows = this.items.length;
      })
      this.getOrdersByUser({userid: this.authId}).then(res => {
        let data = res.filter(r => parseInt(r.payment_status) === 1);
        this.rentedItems = this.allItems.filter(i => data.map(d => { return d.resource_id }).includes(i.id))
      })
    },
    deleteRes(id){
      this.deleteResource({'id': id}).then(()=>{
        this.initComp();
      })
    },
    editRes(id){
      this.$router.push({ name: "Update Resource", params:{ id: id} })
    }
  }
};
</script>